import { LoginForm } from '@flock/shared-ui'
import { Alert, Typography } from '@mui/material'
import React from 'react'
import { ENABLE_SIMULATOR, GATSBY_ENV } from '../constants'

const Login = () => {
  const isSimulator = ENABLE_SIMULATOR
  return (
    <>
      {isSimulator && (
        <Alert
          sx={{
            backgroundColor: 'orange',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
          }}
        >
          <Typography variant="p2">Investor Simulator</Typography>
        </Alert>
      )}
      <LoginForm
        applicationName="Flock's Investor Portal"
        disableMultifactor={GATSBY_ENV !== 'production'}
      />
    </>
  )
}

export default Login
